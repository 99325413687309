<template>
  <div>
    <c-search-box @enter="getAttachList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="첨부파일 설정 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          :isExcelDown="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getAttachList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable && isManager" label="등록" icon="add" @btnClicked="addAttach" />
                <c-btn
                  v-if="editable && deleteable && isManager"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="삭제"
                  icon="remove"
                  @beforeAction="deleteAttach"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveAttach"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable && isManager"
                  :required="true"
                  label="업무구분코드"
                  name="taskClassCd"
                  v-model="data.taskClassCd">
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable && isManager"
                  :required="true"
                  label="업무구분설명"
                  name="taskClassDesc"
                  v-model="data.taskClassDesc">
                </c-text>
              </div>
              <!-- <div class="col-6">
                <c-radio
                  :editable="editable"
                  :comboItems="imgItems"
                  label="이미지사이즈 고정여부"
                  name="onlyImageFlag"
                  v-model="data.onlyImageFlag">
                </c-radio>
              </div>
              <div class="col-3">
                <c-text
                  v-show="data.onlyImageFlag == 'Y'"
                  :editable="editable"
                  label="이미지 넓이"
                  name="imgWidth"
                  type="number"
                  v-model="data.imgWidth">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  v-show="data.onlyImageFlag == 'Y'"
                  :editable="editable"
                  label="이미지 높이"
                  name="imgHeight"
                  type="number"
                  v-model="data.imgHeight">
                </c-text>
              </div> -->
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="허용확장자"
                  name="acceptExt"
                  v-model="data.acceptExt">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="업로드가능파일수"
                  :required="true"
                  name="limitCnt"
                  type="number"
                  suffix="개"
                  :maxlength="2"
                  v-model="data.limitCnt">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="업로드가능파일크기"
                  :required="true"
                  name="limitSize"
                  type="number"
                  suffix="MB"
                  :maxlength="5"
                  v-model="data.limitSize">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="이미지 리사이즈 넓이 (0:원본)"
                  name="resizeWidth"
                  type="number"
                  suffix="px"
                  :maxlength="5"
                  v-model="data.resizeWidth">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  label="이미지 리사이즈 품질 (1:원본)"
                  name="resizeQuality"
                  type="number"
                  :maxlength="5"
                  v-model="data.resizeQuality">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="DragDrop 허용여부"
                  name="dragDropFlag"
                  v-model="data.dragDropFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="파일다중선택 가능여부"
                  name="multipleSelFlag"
                  v-model="data.multipleSelFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="미리보기여부"
                  name="previewFlag"
                  v-model="data.previewFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="설명작성 가능여부"
                  name="explainFlag"
                  v-model="data.explainFlag"
                />
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'attach-manage',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'taskClassCd',
            field: 'taskClassCd',
            label: '업무구분코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'taskClassDesc',
            field: 'taskClassDesc',
            label: '업무구분설명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 70px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        taskClassCd: '',  // 업무구분코드
        taskClassDesc: '',  // 업무구분설명
        acceptExt: '',  // 허용확장자
        limitCnt: '',  // 업로드가능파일수
        limitSize: '',  // 업로드가능파일크기
        dragDropFlag: '',  // DragDrop허용여부
        multipleSelFlag: '',  // 파일다중선택가능여부
        previewFlag: '',  // 미리보기여부
        explainFlag: '',  // 설명작성 가능여부
        onlyImageFlag: 'N',  // 이미지전용 여부
        imgWidth: '',  // 이미지 넓이
        imgHeight: '',  // 이미지 높이
        useFlag: '',  // 사용여부
        resizeWidth: '',
        resizeQuality: '',
      },
      useFlagItems: [],
      imgItems: [],
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.attach.insert.url,
      saveType: 'POST',
      searchUrl: '',
      isManager: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.imgItems = [
        { code: 'N', codeName: this.$language('일반') },
        { code: 'Y', codeName: this.$language('이미지사이즈 고정') },
      ]
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.editable = this.$route.meta.editable;
      this.isManager = (this.$store.getters.user.userId === 'U000000001');
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.attach.list.url;
      this.detailUrl = selectConfig.sys.attach.get.url;
      this.checkUrl = selectConfig.sys.attach.check.url;
      this.insertUrl = transactionConfig.sys.attach.insert.url;
      this.updateUrl = transactionConfig.sys.attach.update.url;
      this.deleteUrl = transactionConfig.sys.attach.delete.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getAttachList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.taskClassCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addAttach() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        taskClassCd: '',
        taskClassDesc: '',
        acceptExt: '',
        limitCnt: '5',
        limitSize: '10',
        dragDropFlag: 'Y',
        multipleSelFlag: 'Y',
        previewFlag: 'N',
        explainFlag: 'N',
        onlyImageFlag: 'N',  // 이미지전용 여부
        imgWidth: '0',  // 이미지 넓이
        imgHeight: '0',  // 이미지 높이
        useFlag: 'Y',
        resizeWidth: 0,
        resizeQuality: 1,
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        taskClassCd: '',
        taskClassDesc: '',
        acceptExt: '',
        limitCnt: '',
        limitSize: '',
        dragDropFlag: '',
        multipleSelFlag: '',
        previewFlag: '',
        explainFlag: '',
        onlyImageFlag: 'N',  // 이미지전용 여부
        imgWidth: '0',  // 이미지 넓이
        imgHeight: '0',  // 이미지 높이
        useFlag: '',
        resizeWidth: '',
        resizeQuality: '',
      };
    },
    saveAttach() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.data.taskClassCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내',
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteAttach() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.taskClassCd);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ taskClassCd: result.data.taskClassCd })
      } else {
        this.getAttachList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getAttachList();
    },
  }
};
</script>
